* {
    box-sizing: border-box;
}

html,body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
	-webkit-tap-highlight-color: transparent;
}

body,button,input,optgroup,select,textarea {
    font-family: -apple-system, sans-serif,Georgia, "Times New Roman","Microsoft YaHei", "微软雅黑";
}

body {
    font-size: 16px;
    line-height: 1.5;
	color: #2C2E34;
    font-family: PingFangSC-Regular, PingFang SC;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    user-select: none;
}

a {
    color: #1AB6FF;
    text-decoration: none;
    cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: 0.5em;
    color: #2C2E34;
}

h1 { font-size: 1.5em; }
h2 { font-size: 1.25em; }
h3 { font-size: 1.125em; }
h4 { font-size: 1em; }
h5 { font-size: 0.875em; }
h6 { font-size: 0.75em; }

p,dl,ol,ul,pre,blockquote {
    margin-top: 1em;
    margin-bottom: 1em;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

img { vertical-align:top; border: 0;-webkit-user-drag: none;}

:where(.css-dev-only-do-not-override-diro6f).ant-modal-root .ant-modal-wrap {z-index: 3500;}
:where(.css-diro6f).ant-modal-root .ant-modal-wrap{z-index: 3500;}